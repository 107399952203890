<script setup lang="ts">
import { type FeaturedBlockFragment } from '~/generated/operations'

type Fragment = FeaturedBlockFragment
type FeaturedItem = Extract<
	NonNullable<Fragment['featured'][0]>,
	{
		__typename: `collection_featured_Entry`
	}
>

const props = defineProps<{
	block: Fragment & {
		featured: FeaturedItem[]
	}
}>()

let title: string
let description: string
if (props.block.textSetting === 'custom') {
	title = props.block.heading!
	description = props.block.description!
} else if (props.block.textSetting === 'default' && props.block.featured[0]) {
	title = props.block.featured[0].title!
	description = props.block.featured[0].description!
}
</script>
<template>
	<CollectionFeatured
		v-if="block.featured && block.featured[0]"
		:label="block.prefix"
		:title="title"
		:description="description"
		:slug="block.featured[0].slug!"
		:type="
			block.featuredDisplayType
				? (block.featuredDisplayType as 'carrousel' | 'stack')
				: undefined
		"
	/>
</template>
